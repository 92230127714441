import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function BottomAnimeCard({ position, title, cover }) {
  const coverArt = getImage(cover)

  const isPrev = position === 0

  return (
    <div className="flex flex-nowrap items-center rounded-md drop-shadow-md bg-stone-50 hover:bg-gradient-to-b from-stone-400 to-stone-500 hover:text-stone-50">
      <div className={`w-24 h-auto ${isPrev ? `` : `order-last`}`}>
        <GatsbyImage image={coverArt} alt={`${isPrev ? `Previous anime` : `Next anime`} ${title} cover art`} className={isPrev ? `rounded-l-md` : `rounded-r-md`} />
      </div>
      <div className={`basis-4/5 px-2 ${isPrev ? `` : `text-right`}`}>
        <p className="pb-0.5 lg:pb-1 font-semibold text-sm lg:text-base opacity-50">
          {isPrev ? `Previous anime` : `Next anime`}
        </p>
        <p className="text-base lg:text-lg line-clamp-3">
          {title}
        </p>
      </div>
    </div>
  )
}
