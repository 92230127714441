import { graphql, Script } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { SEO } from '../components/SEO'
import { BottomLinks } from '../components/BottomLinks'
import SocialShareButtons from '../components/SocialShareButtons'
import AdsBanner from '../components/AdsBanner'

export default function AnimeDetails({ data }) {
  const { html } = data.markdownRemark
  const {
    coverArt,
    japTitleRomaji,
    japTitleKanji,
    engTitle,
    noOfEps,
    season,
    year,
    myScore,
    disclaimer,
    whereToWatchLink,
  } = data.markdownRemark.frontmatter

  const sameJapAndEngTitle = japTitleRomaji === engTitle
  const seoTitle = sameJapAndEngTitle ? japTitleRomaji : `${japTitleRomaji} (${engTitle})`
  const coverArtImg = getImage(coverArt)

  // const japTitleKeywords = `is ${japTitleRomaji} good, ${japTitleRomaji} worth watching, ${japTitleRomaji} review and opinion`

  // const engTitleKeywords = !sameJapAndEngTitle ?
  //   `, is ${engTitle} good, ${engTitle} worth watching, ${engTitle} review and opinion`
  //   : ``

  // const japTitleIndoKeywords = `, apakah anime ${japTitleRomaji} bagus atau tidak, ${japTitleRomaji} layak ditonton, perlu harus nonton ${japTitleRomaji} ga, ${japTitleRomaji} pendapat dan opini`

  // const engTitleIndoKeywords = !sameJapAndEngTitle ?
  //   `, apakah anime ${engTitle} bagus atau tidak, ${engTitle} layak ditonton, perlu harus nonton ${engTitle} ga, ${engTitle} pendapat dan opini`
  //   : ``

  // const seoKeywords = japTitleKeywords + engTitleKeywords + japTitleIndoKeywords + engTitleIndoKeywords

  const titleKeywords = sameJapAndEngTitle ? `${japTitleRomaji}` : `${japTitleRomaji} ${engTitle}`

  const keywords = `is ${titleKeywords} good, ${titleKeywords} worth watching, should i you watch ${titleKeywords}, ${titleKeywords} review opinion mal myanimelist my anime list, stream watch ${titleKeywords} online free`

  const prevAnimeDetails = data.prevAnime
  const nextAnimeDetails = data.nextAnime

  let seasonEmoji;
  if (season === "Spring")
    seasonEmoji = '🌸'
  else if (season === "Summer")
    seasonEmoji = '🌊'
  else if (season === "Fall")
    seasonEmoji = '🍁'
  else
    seasonEmoji = '❄'

  return (
    <Layout>
      <div className="font-sans text-stone-700 mx-auto flex justify-center">
        <div className="max-w-4xl">
          <section className="pt-6 pb-4 md:pt-10 md:pb-6 flex flex-wrap items-center">
            <div className="mx-auto sm:mx-0 sm:px-6 sm:w-1/3">
              <GatsbyImage image={coverArtImg} alt={`${seoTitle} cover art`} className="drop-shadow-lg max-w-[315px]" />
            </div>
            <div className="px-2 md:px-4 w-full sm:w-2/3">
              <h1 className="mt-4 sm:mt-0 mb-4 font-bold text-2xl md:text-3xl">
                Should I Watch {japTitleRomaji}?
              </h1>
              <section className="font-semibold text-base md:text-lg">
                <p><span className="font-bold">Japanese title:</span> {japTitleKanji}</p>
                <p><span className="font-bold">English title:</span> {engTitle}</p>
                {
                  noOfEps ?
                    <p><span className="font-bold">No. of episodes:</span> {noOfEps}</p>
                    : ``
                }
                <p><span className="font-bold">Season:</span> {seasonEmoji} {season} {year} {seasonEmoji}</p>
                {
                  myScore ?
                    <p><span className="font-bold">My personal score:</span> {myScore}/10</p>
                    : ``
                }
              </section>
            </div>
          </section>

          <section className="px-2 md:px-4 pt-4 md:pt-6 font-semibold text-base md:text-lg">
            {disclaimer ?
              <h2 className="pb-4 animate-bounce text-lg md:text-2xl">
                Disclaimer: {disclaimer}
              </h2>
              : ''
            }
            <p className="pb-2 md:pb-4 font-semibold text-base md:text-lg">
              So you're probably asking whether {japTitleRomaji} is good, or if it's worth watching.
            </p>
            <div className="space-y-4" dangerouslySetInnerHTML={{ __html: html }} />
            {whereToWatchLink &&
              <p className="pt-2 md:pt-4 font-semibold text-base md:text-lg">
                You can watch this anime <a href={whereToWatchLink} target="_blank" rel="noopener noreferrer" className="underline">here</a>!
              </p>
            }
          </section>

          <section className="px-2 md:px-4 pt-2 md:pt-4" id="share">
            <p className="pb-2 md:pb-4 font-semibold text-base md:text-lg">
              Feel free to share this on social media!
            </p>
            <SocialShareButtons />
          </section>

          <section className="px-2 md:px-4 pt-4 md:pt-6">
            <BottomLinks prevAnime={prevAnimeDetails} nextAnime={nextAnimeDetails} />
          </section>

          <section>
            <p className="text-[1px] text-transparent select-none">
              {"Tags: " + keywords}
            </p>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const {
    title,
    japTitleRomaji,
    engTitle,
    seoDesc,
    coverArt,
    slug
  } = data.markdownRemark.frontmatter

  const sameJapAndEngTitle = japTitleRomaji === engTitle
  const seoTitle = sameJapAndEngTitle ? japTitleRomaji : `${japTitleRomaji} (${engTitle})`

  return (
    <>
      <SEO
        title={`Should I Watch ${seoTitle}? ${title}`}
        description={seoDesc}
        image={coverArt.publicURL}
        pathname={`/review/${slug}`}
      />
      <Script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9850042708275295"
        crossorigin="anonymous"></Script>
    </>
  )
}

export const query = graphql`
  query AnimeDetails($slug: String, $prevSlug: String, $nextSlug: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        engTitle
        japTitleKanji
        japTitleRomaji
        myScore
        quickAnswer
        season
        year
        noOfEps
        seoDesc
        disclaimer
        slug
        whereToWatchLink
        coverArt {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    prevAnime: markdownRemark(frontmatter: {slug: {eq: $prevSlug}}) {
      frontmatter {
        slug
        japTitleRomaji
        coverArt {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    nextAnime: markdownRemark(frontmatter: {slug: {eq: $nextSlug}}) {
      frontmatter {
        slug
        japTitleRomaji
        coverArt {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`