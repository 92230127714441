import { Link } from 'gatsby'
import React from 'react'
import BottomAnimeCard from './BottomAnimeCard'

export const BottomLinks = ({ prevAnime, nextAnime }) => {
  return (
    <section className="pb-12 md:pb-20 font-sans font-bold text-base md:text-lg">
      <div className="grid grid-cols-1 lg:grid-cols-3 items-center space-y-4 lg:space-y-0">

        <div className="mx-auto my-4 order-1 lg:order-2">
          <a href="/" className="px-3 py-1 md:px-4 md:py-2 rounded-md hover:drop-shadow-md hover:bg-gradient-to-b from-stone-400 to-stone-500 hover:text-stone-50">
            Back to Home
          </a>
        </div>

        <div className="order-2 lg:order-1">
          {prevAnime ?
            <a href={`/review/${prevAnime.frontmatter.slug}`}>
              <BottomAnimeCard position={0} title={prevAnime.frontmatter.japTitleRomaji} cover={prevAnime.frontmatter.coverArt} />
            </a>
            : ""}
        </div>

        <div className="order-3">
          {nextAnime ?
            <a href={`/review/${nextAnime.frontmatter.slug}`}>
              <BottomAnimeCard position={1} title={nextAnime.frontmatter.japTitleRomaji} cover={nextAnime.frontmatter.coverArt} />
            </a>
            : ""}
        </div>

      </div>
    </section>
  )
}